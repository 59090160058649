<template>
  <Layout>
    <template v-if="this.$route.name == 'add-role'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="role">
              <b-form-group id="input-group-1">
                <label for="input-2">Role Name <span style="color: red;">*</span></label>
                <b-form-input id="input-2" v-model="form.name" placeholder="Enter Role Name" :class="{
                  'is-invalid': submitted && $v.form.name.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                  Role Name is required.
                </div>
              </b-form-group>
              <b-form-group id="input-group-1">
                <label for="input-2">Product Type</label>
                <b-form-select id="input-2" v-model="form.product_type" @change="getPermissions(form.product_type)"
                  :options="productType" value-field="value" text-field="name" :class="{
                    'is-invalid': submitted && $v.form.product_type.$error,
                  }">
                </b-form-select>
                <div v-if="submitted && !$v.form.product_type.required" class="invalid-feedback">
                  Product Type is required.
                </div>
              </b-form-group>
              <template v-if="form.product_type == 'docengage'">
                <b-form-group>
                  <label for="input-multi">Select Brand</label>
                  <b-form-select id="input-multi" v-model="form.brand_id" :options="forumLists" value-field="id"
                    text-field="title" :class="{
                      'is-invalid': submitted && $v.form.brand_id.$error,
                    }">
                  </b-form-select>
                  <div v-if="submitted && !$v.form.brand_id.required" class="invalid-feedback">
                    Brand name is required.
                  </div>
                </b-form-group>
              </template>
              <b-form-group>
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label for="input-2" v-if="form.product_type == 'admin'">Admin Permission</label>
                  <label for="input-2" v-else-if="form.product_type == 'docengage'">DocEngage Permission</label>
                  <b-form-checkbox v-model="status" button button-variant="secondary" size="sm"
                    v-if="form.product_type != null && form.product_type != ''">
                    <template v-if="status">Unselect All</template>
                    <template v-else>Select All</template>
                  </b-form-checkbox>
                </div>
                <div class="role_permission" v-if="showData">
                  <div class="" v-for="p in permission" :key="p.id">
                    <b-card style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; margin-bottom: 0">
                      <div class="d-flex align-items-start">
                        <input class="mt-1 mr-1" type="checkbox" @input="checkAll($event, p.id)" v-model="form.permission"
                          :value="p.id">
                        {{ p.name }}
                      </div>
                      <template v-if="p.sub_menu.length > 0">
                        <div v-for="sub_menu in p.sub_menu" :key="sub_menu.id" class="ml-2 d-flex align-items-start">
                          <input class="mt-1 mr-1" type="checkbox" @input="subMenuCheck(sub_menu.id, sub_menu.parent)"
                            v-model="form.permission" :value="sub_menu.id"> {{ sub_menu.name }}
                        </div>
                      </template>
                    </b-card>
                  </div>
                </div>
              </b-form-group>
              <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                  v-if="this.$route.name == 'add-role'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import roleMixin from "../../../mixins/ModuleJs/role";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, roleMixin],
  data() {
    return {
      submitted: false,
      title1: "Add Role",
      title2: "Edit Role",
      items: [
        {
          text: "Back",
          href: "/role",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      name: { required },
      brand_id: { required: requiredIf(form => form.product_type == 'docengage') },
      product_type: { required }
    },
  },
};
</script>
