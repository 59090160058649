import {
  role,
  product_type,
  forum
} from "../../js/path";
export default {
  data() {
    return {
      showData: false,
      status: false,
      pageNo: null,
      fields: [{
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Role Name",
        },
        {
          key: "permissions",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      permission: [],
      form: {
        name: "",
        permission: [],
        product_type: "",
        brand_id: ""
      },
      productType: [],
      forumLists: [],
      params: "",
      tableData: [],
      activeTab: "all",
      key: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected: [],
    };
  },
  methods: {
    checkAll(e, id) {
      let data = this.permission.filter((p) => p.id == id);
      if (data != '') {
        if (typeof data[0].sub_menu != undefined && data[0].sub_menu.length > 0) {
          data[0].sub_menu.map(parent => {
            if (e.target.checked) {
              if (!this.form.permission.includes(parent.id)) {
                this.form.permission.push(parent.id);
              } else {
                this.form.permission.splice(this.form.permission.indexOf(parent.id), 1);
              }
            } else {
              if (this.form.permission.includes(parent.id)) {
                this.form.permission.splice(this.form.permission.indexOf(parent.id), 1);
              }
            }
          });
        }
      }
    },
    subMenuCheck(id, parent) {
      if (!this.form.permission.includes(id)) {
        this.form.permission.push(id);
      } else {
        this.form.permission.splice(this.form.permission.indexOf(id), 1);
      }
      let data = this.permission
        .filter((p) => p.id == parent);
      if (data != '') {
        let length = data[0].sub_menu.length;
        if (typeof data[0].sub_menu != undefined && data[0].sub_menu.length > 0) {
          let count = 0;
          data[0].sub_menu.map(sub => {
            if (this.form.permission.includes(sub.id)) {
              count++;
            }
          });
          if (length == count) {
            this.form.permission.push(parent);
          }
        }
      }
    },
    searchFor() {
      if (this.filter.length > 1) this.fetchData("search");
      else if (this.filter.length == 0) this.fetchData("search");
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData('search');
        }
      } else if (this.filter.length == 0) this.fetchData('search');
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = role.roleUrl;
        if (pagination == "search") {
          url = role.roleUrl + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async getPermissions(url) {
      this.$store.commit("loader/updateStatus", true);
      this.showData = false;
      try {
        if (this.form.product_type == 'docengage') {
          url = role.permissionUrl + "?filter=docengage&nopagination";
        } else if (this.form.product_type == 'admin') {
          url = role.permissionUrl + "?nopagination";
        }
        const data = await this.getRequest(url);
        if (data.status) {
          if (data.response.data) {
            this.$store.commit("loader/updateStatus", false);
            this.showData = true;
            this.permission = data.response.data;
            this.permission
              .filter((p) => p.parent == 0)
              .map((item) => {
                item.sub_menu = this.permission.filter(
                  (perm) => perm.parent == item.id
                );
              });
            this.permission = this.permission.filter((p) => p.parent == 0);
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please try again!",
        });
      }
    },
    async fetchAdminPermission() {
      let url = role.permissionUrl + "?nopagination";
      const data = await this.getRequest(url);
      if (data.status) {
        this.form.permission = data.response.data.map((a) => {
          return a.id
        });
      }
    },
    async fetchDocEngagePermission() {
      try {
        const url = role.permissionUrl + "?filter=docengage&nopagination";
        const data = await this.getRequest(url);
        if (data.status) {
          this.form.permission = data.response.data.map((a) => {
            return a.id
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please try again!",
        });
      }
    },
    async fetchRole(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = role.roleUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.permission.data) {
            this.showData = true;
            this.permission = responseData.permission.data;
            // this.permission = this.permission.filter(permission => !this.removePermissions.includes(permission.name));
            this.form.permission.push(this.permission[0].id);
            this.permission
              .filter((p) => p.parent == 0)
              .map((item) => {
                item.sub_menu = this.permission.filter(
                  (perm) => perm.parent == item.id
                );
              });
            this.permission = this.permission.filter((p) => p.parent == 0);
          }
          this.form.name = data.response.role.name;
          this.form.permission = data.response.rolePermissions;
          this.form.brand_id = data.response.role.brand_id;
          this.form.product_type = data.response.role.product_type;          
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteRole(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = role.roleUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchProductType() {
      try {
        const url = product_type.getProductType;
        const data = await this.getRequest(url);
        if (data.status) {
          this.productType = data.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please try again!",
        });
      }
    },
    async fetchForum() {
      try {
        const url = forum.fetchAllActiveForum;
        const data = await this.getRequest(url);
        if (data.status) {
          this.forumLists = data.response.data;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please try again!",
        });
      }
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        if (this.form.permission.length <= 0) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Permission is Required"
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = role.roleUrl;
        if (this.$route.name == 'edit-role') {
          url = role.roleUrl + '/' + this.$route.params.id;
        }
        let dataAppend = new FormData();
        for (var key in this.form) {
          if (key != "permission") {
            dataAppend.append(key, this.form[key]);
          }
        }
        this.form.permission.forEach(a => dataAppend.append("permission[]", a));
        if (this.$route.name == 'edit-role') {
          dataAppend.append('_method', 'put');
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/role");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.fetchData(value);
          this.pageNo = this.currentPage;
        }
      },
    },
    status(v) {
      if (v == 1) {
        if (this.form.product_type == 'admin') {
          this.fetchAdminPermission();
        } else if (this.form.product_type == 'docengage') {
          this.fetchDocEngagePermission();
        }
      } else {
        this.form.permission = [];
      }
    }
  },
  created() {
    if (this.$route.name == "add-role" || this.$route.name == "edit-role") {
      this.fetchProductType();
      this.fetchForum();
      if (this.$route.name == "edit-role") {
        this.fetchRole(this.$route.params.id);
      }
    } else {
      this.fetchData("all");
    }
  },
};